import { createStore } from 'vuex'
import router from '@/router';
import axios from 'axios'

export default createStore({
    state: {
        isLoggedIn: !!localStorage.getItem("token"),
        role: localStorage.getItem("role"),
        userData: {},
        currentPage: null,
        unitId: "",
        gender: "",
        maritalStatus: "",
        fromAge: "",
        toAge: "",
        page:"",
        yearId:"",
        classFilter:"",
        groupFilter:"",
    },
    getters: {},
    mutations: {
        loginUser(state, payload) {
            localStorage.setItem("token", payload);
            state.isLoggedIn = true
        },
        role(state, payload) {
            state.role = payload
        },
        logoutUser(state) {
            axios({
                method: "POST",
                url: "/user/logout",
                headers: {
                    "token": localStorage.getItem("token")
                }
            }).then(response => {
                if (response.data) {
                    state.isLoggedIn = false
                    state.currentPage = null
                    localStorage.clear();
                    // localStorage.removeItem("token");
                    router.push('/')
                }
            })
        },
        userData(state, item) {
            state.userData = item;
        },
        changeCurrentPage(state, item) {
            state.currentPage = item
        },
        changeUnit(state, item) {
            state.unitId = item
        },
        changeCurrentPageMember(state, item) {
            state.currentPage = item
        },
        changeCurrentPageStudent(state, item){
            state.page = item
        },
        changeYear(state, item){
            state.yearId = item
        },
        changeClass(state, item){
            state.classFilter = item
        },
        changeGroup(state, item){
            state.groupFilter = item
        },
        changeUnitMember(state, item) {
            state.unitId = item
        },
        changeGender(state, item) {
            state.gender = item
        },
        changeMaritalStatus(state, item) {
            state.maritalStatus = item
        },
        changeFromAge(state, item) {
            state.fromAge = item
        },
        changeToAge(state, item) {
            state.toAge = item
        },

    },
    actions: {},
    modules: {}
})